import React, { FC } from 'react';

import * as BIManager from '../../../../utils/bi';
import { useTemplateContext } from '../../../../context/TemplateContext/TemplateContext';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import CornerArrowIcon from './CornerArrowIcon.svg';
import s from './EditTemplateButton.scss';

interface EditTemplateButtonProps extends WithTranslation {
  dataHook?: string;
}

export const EditTemplateButtonCmp: FC<EditTemplateButtonProps> = ({ t, dataHook }) => {
  const { template } = useTemplateContext();

  return (
    <a
      className={s.button}
      href={template.editorUrl}
      target="_blank"
      onClick={() => BIManager.reportStudioTemplateEdit({ template })}
      rel="noreferrer"
      data-hook={dataHook}
    >
      <div className={s.content}>
        {t('template.viewer.editTemplate')}
        <CornerArrowIcon className={s.animatedIcon} />
      </div>
    </a>
  );
};

export const EditTemplateButton = withTranslation()(EditTemplateButtonCmp);
