import React from 'react';
import { MobileApp } from '../components/MobileApp/MobileApp';
import { DesktopApp } from '../components/DesktopApp/DesktopApp';
import './App.scss';

interface Props {
  mobile: boolean;
}

export const App = ({ mobile }: Props) => (mobile ? <MobileApp dataHook="app" /> : <DesktopApp dataHook="app" />);
