import React, { useContext } from 'react';

interface ConfigContextShape {
  locale: string;
  lang?: string;
  dealerCmsTranslationsUrl: string;
  dealerLightboxUrl: string;
}

export const defaultContextValue: ConfigContextShape = {
  locale: '',
  dealerCmsTranslationsUrl: '',
  dealerLightboxUrl: '',
};

const ConfigContext = React.createContext<ConfigContextShape>(defaultContextValue);
ConfigContext.displayName = 'ConfigContext';

const useConfigContext = () => useContext(ConfigContext);

export { ConfigContext, ConfigContextShape, useConfigContext };
