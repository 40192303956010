import React, { FC } from 'react';
import { VIEW_MODE } from '../../../utils/helpers';
import s from './WixStudioToolBar.scss';
import { WixStudioLogo } from '../WixStudioLogo/WixStudioLogo';
import { wixStudioToolBarDataHooks } from './WixStudioToolBar.dataHooks';
import { Switcher } from './Switcher/Switcher';
import { EditTemplateButton } from './EditTemplateButton/EditTemplateButton';

interface Props {
  dataHook?: string;
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
}

export const WixStudioToolBar: FC<Props> = ({ viewMode, toggleViewMode, dataHook }) => {
  return (
    <div data-hook={dataHook} className={s.wixStudioToolBar}>
      <div className={s.logo}>
        <WixStudioLogo dataHook={wixStudioToolBarDataHooks.logo} color="dark" />
      </div>
      <div className={s.switcher}>
        <Switcher
          viewMode={viewMode}
          toggleViewMode={toggleViewMode}
          dataHook={wixStudioToolBarDataHooks.deviceSwitcher}
        />
      </div>
      <EditTemplateButton dataHook={wixStudioToolBarDataHooks.editorLink} />
    </div>
  );
};
