import React, { useContext } from 'react';
import { VIEW_MODE } from '../../utils/helpers';

interface UIContextShape {
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => void;
  isEditButtonHidden: boolean;
  isInfoShown: boolean;
  setIsInfoShown: (isInfoShown: boolean) => void;
}

export const defaultUIContextValue: UIContextShape = {
  viewMode: VIEW_MODE.DESKTOP,
  toggleViewMode: () => {},
  isEditButtonHidden: false,
  isInfoShown: false,
  setIsInfoShown: () => {},
};

const UIContext = React.createContext<UIContextShape>(defaultUIContextValue);
UIContext.displayName = 'UIContext';

const useUIContext = () => useContext(UIContext);

export { UIContext, UIContextShape, useUIContext };
