import React, { FC, PropsWithChildren } from 'react';
import { WixLogo } from '../WixLogo/WixLogo';
import s from './MobileToolbar.scss';
import { mobileToolbarDataHooks } from './MobileToolbar.dataHooks';

interface MobileToolbarProps {
  dataHook?: string;
  onLogoClick?: () => any;
}

export const MobileToolbar: FC<PropsWithChildren<MobileToolbarProps>> = ({ children, onLogoClick, dataHook }) => (
  <div data-hook={dataHook} className={s.mobileToolBar}>
    <WixLogo onClick={onLogoClick} dataHook={mobileToolbarDataHooks.logo} />
    {children}
  </div>
);
