import React, { FC } from 'react';
import s from './WixLogo.scss';

interface Props {
  dataHook?: string;
  onClick?: () => any;
}

export const WixLogo: FC<Props> = ({ onClick, dataHook }) => (
  <a data-hook={dataHook} href="/" onClick={onClick} className={s.logo}>
    <span className={s.hide}>wix.com</span>
  </a>
);
