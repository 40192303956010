import React, { FC, useEffect } from 'react';
import { ArrowLeftSmall } from '@wix/wix-ui-icons-common';
import cs from 'classnames';
import { VIEW_MODE } from '../../utils/helpers';
import s from './ToolBar.scss';
import { WixLogo } from '../partials/WixLogo/WixLogo';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { InfoButton } from '../InfoButton/InfoButton';
import { useUIContext } from '../../context/UIContext/UIContext';
import { reportEditorClick } from '../../utils/bi';
import { toolBarDataHooks } from './ToolBar.dataHooks';

const ToolBarCmp: FC<{ dataHook?: string } & WithTranslation> = ({ t, dataHook }) => {
  const { viewMode, toggleViewMode } = useUIContext();
  const { template } = useTemplateContext();
  const isShowBackButton = template.backToTemplatesUrl || template.origin !== undefined;

  useEffect(() => {
    window.templateEditUrl = template.editorUrl; // Adoric need to have this link in the window
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorClick = () => {
    reportEditorClick({ origin: 'viewer_top' });
  };

  const onBackToTemplatesClick = () => {
    if (template.origin === 'intro') {
      window.history.back();
    } else if (template.backToTemplatesUrl !== undefined) {
      window.location.assign(template.backToTemplatesUrl);
    }
  };

  return (
    <div data-hook={dataHook} className={s.toolBar}>
      <div className={s.row}>
        <div className={s.logo}>
          <WixLogo dataHook={toolBarDataHooks.logo} />
        </div>
        {isShowBackButton && (
          <>
            <hr className={s.backButtonDivider} />
            <div
              onClick={onBackToTemplatesClick}
              className={s.backToTemplatesButton}
              data-hook={toolBarDataHooks.backToTemplatesButton}
            >
              <ArrowLeftSmall className={s.backButtonIcon} />
              <div className={s.label}>{t('template.viewer.header.backToTemplates')}</div>
            </div>
          </>
        )}
        <div className={s.deviceToggle}>
          <button
            data-hook={toolBarDataHooks.desktopView}
            className={cs(s.desktopIcon, { [s.selected]: viewMode === VIEW_MODE.DESKTOP })}
            onClick={() => toggleViewMode(VIEW_MODE.DESKTOP)}
          >
            <span className={s.hide}>{t('a11y.desktop.button')}</span>
          </button>
          <hr className={s.hr} />
          <button
            data-hook={toolBarDataHooks.mobileView}
            className={cs(s.mobileIcon, { [s.selected]: viewMode === VIEW_MODE.MOBILE })}
            onClick={() => toggleViewMode(VIEW_MODE.MOBILE)}
          >
            <span className={s.hide}>{t('a11y.mobile.button')}</span>
          </button>
        </div>
      </div>
      <div className={s.actionMenu}>
        <div className={s.elementsGroup}>
          <p data-hook={toolBarDataHooks.toolBarTitle} className={isShowBackButton ? s.responsiveTitle : s.title}>
            {t('template.viewer.title')}
          </p>
          <InfoButton dataHook={toolBarDataHooks.infoView} />
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className={s.button}
            data-hook={toolBarDataHooks.editorLink}
            href={template.editorUrl}
            target="_blank"
            tabIndex={0}
            onClick={() => onEditorClick()}
          >
            {t('template.viewer.edit.button')}
          </a>
        </div>
      </div>
    </div>
  );
};

export const ToolBar = withTranslation()(ToolBarCmp);
