import React, { useContext } from 'react';

interface ExperimentContextShape {
  activeExperiments: string[];
}

export const defaultContextValue: ExperimentContextShape = {
  activeExperiments: [],
};

const ExperimentContext = React.createContext<ExperimentContextShape>(defaultContextValue);
ExperimentContext.displayName = 'ExperimentContext';

const useExperimentContext = () => useContext(ExperimentContext);

export { ExperimentContext, ExperimentContextShape, useExperimentContext };
