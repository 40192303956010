import React, { FC, PropsWithChildren } from 'react';
import { ConfigContext, ConfigContextShape } from './ConfigContext';

interface ConfigContextProviderProps {
  initialState: {
    config: ConfigContextShape;
  };
}

export const ConfigContextProvider: FC<PropsWithChildren<ConfigContextProviderProps>> = ({
  initialState,
  children,
}) => {
  const contextValue: ConfigContextShape = {
    ...initialState.config,
  };

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
};
