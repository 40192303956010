export const mobileAppDataHooks = {
  newMobileEditorxToolbar: 'new-mobile-editorx-toolbar',
  wixStudioToolbar: 'wix-studio-toolbar',
  biggerScreen: 'bigger-screen',
  mobileToolBar: 'mobile-tool-bar',
  infoView: 'info-view',
  mobileIframe: 'mobile-iframe',
  infoPopUp: 'info-pop-up',
  seeAllExpressions: 'see-all-expressions',
};
