import React, { FC } from 'react';
import s from './desktopView.scss';
import { desktopViewDataHooks } from './DesktopView.dataHooks';

interface Props {
  dataHook?: string;
  url: string;
  title: string;
}

export const DesktopView: FC<Props> = ({ url, title, dataHook }) => (
  <div data-hook={dataHook} className={s.display}>
    <iframe
      data-hook={desktopViewDataHooks.desktopIframe}
      src={url}
      title={title}
      width="100%"
      height="100%"
      className={s.iframe}
    />
  </div>
);
