import React, { FC } from 'react';
import s from './MobileEditorXToolbar.scss';
import * as BIManager from '../../../utils/bi';
import { EditorXLogo } from '../EditorXLogo/EditorXLogo';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

import { mobileEditorXToolbarDataHooks } from './MobileEditorXToolbar.dataHooks';

interface Props extends WithTranslation {
  dataHook?: string;
  template: any;
  isEditButtonHidden: boolean;
}

const MobileEditorXToolbarCmp: FC<Props> = ({ t, template, isEditButtonHidden, dataHook }) => {
  const getStartedText = t('template.viewer.getStarted');
  const reportGetStartedClick = () => BIManager.reportActionButtonClick('get_started', getStartedText);

  return (
    <div className={s.mobileEditorXToolbar} data-hook={dataHook}>
      <EditorXLogo isLarge={false} dataHook={mobileEditorXToolbarDataHooks.logo} />
      {!isEditButtonHidden && (
        <a
          data-hook={mobileEditorXToolbarDataHooks.getStartedButton}
          className={s.button}
          href={template.editorUrl}
          onClick={reportGetStartedClick}
        >
          {getStartedText}
        </a>
      )}
    </div>
  );
};

export const MobileEditorXToolbar = withTranslation()(MobileEditorXToolbarCmp);
