import React, { FC } from 'react';
import s from './infoButton.scss';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import { useUIContext } from '../../context/UIContext/UIContext';
import { reportInfoClick } from '../../utils/bi';

export interface InfoButtonProps extends WithTranslation {
  dataHook?: string;
}

const InfoButtonCmp: FC<InfoButtonProps> = ({ t, dataHook }) => {
  const { isInfoShown, setIsInfoShown } = useUIContext();

  const toggleInfoMode = (newValue: boolean) => {
    if (newValue) {
      reportInfoClick();
    }
    setIsInfoShown(newValue);
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      data-hook={dataHook}
      className={s.infoText}
      tabIndex={0}
      onClick={() => toggleInfoMode(!isInfoShown)}
      onKeyPress={(e: any) => e.charCode === 13 && toggleInfoMode(!isInfoShown)}
      role="dialog"
      href="#"
    >
      {t('template.viewer.read.more')}
    </a>
  );
};

export const InfoButton = withTranslation()(InfoButtonCmp);
