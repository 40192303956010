import * as BIManager from './bi';

export interface DealerLightboxApiProps {
  translationsUrl: string;
  dealerOptions?: any;
  locale?: string;
  onView?: Function;
  onClick?: Function;
  onClose?: Function;
  location: string;
  metasiteGuid?: string;
  viewerUrl: string;
}

interface InitDealerProps {
  editorSessionId: string;
  siteId: string;
  metaSiteId: string;
  dealerCmsTranslationsUrl: string;
  lang?: string;
  dealerLightboxUrl: string;
}

export const initDealer = ({
  editorSessionId,
  siteId,
  metaSiteId,
  dealerCmsTranslationsUrl,
  lang,
  dealerLightboxUrl,
}: InitDealerProps) => {
  if (window.wixDealerLightbox) {
    initNewDealer(window.wixDealerLightbox.DealerLightboxApi, {
      editorSessionId,
      siteId,
      metaSiteId,
      dealerCmsTranslationsUrl,
      lang,
      dealerLightboxUrl,
    });
  }
};

function initNewDealer(
  DealerLightboxApi: any,
  { editorSessionId, siteId, metaSiteId, dealerCmsTranslationsUrl, lang, dealerLightboxUrl }: InitDealerProps,
) {
  const dealerLightboxApi = new DealerLightboxApi();
  dealerLightboxApi.initLightbox({
    location: 'TEMPLATE_VIEWER',
    translationsUrl: dealerCmsTranslationsUrl,
    locale: lang,
    viewerUrl: dealerLightboxUrl,
    onClick: () => BIManager.reportEditorClick({ origin: 'dealerApi' }),
    dealerOptions: {
      editorSessionId,
      siteId,
      metaSiteId,
    },
  });
}
