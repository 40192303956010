import React, { FC } from 'react';
import cs from 'classnames';
import s from './EditorXLogo.scss';
import * as BIManager from '../../../utils/bi';
import Logo from './EditorX_logo_14x60_black.svg';

interface Props {
  dataHook?: string;
  isLarge: boolean;
}

export const EditorXLogo: FC<Props> = ({ isLarge, dataHook }) => (
  <a data-hook={dataHook} href="https://www.wix.com/editorx" onClick={BIManager.reportHomeClick} className={s.logo}>
    <Logo className={cs(s.image, { [s.large]: isLarge })} fill="#000" />
    <span className={s.hide}>EditorX</span>
  </a>
);
