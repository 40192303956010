import React, { FC } from 'react';
import cs from 'classnames';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

import s from './infoPopUp.scss';
import { StoreTemplate, useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useUIContext } from '../../context/UIContext/UIContext';
import { reportEditorClick } from '../../utils/bi';
import { infoPopUpDataHooks } from './InfoPopUp.dataHooks';

interface EditButtonProps extends WithTranslation {
  dataHook?: string;
  template: StoreTemplate;
  editorClick: () => any;
  target?: string;
}

const EditButton: FC<EditButtonProps> = ({ t, template, editorClick, target }) => (
  <a
    data-hook={infoPopUpDataHooks.cardEditorUrl}
    className={cs(s.editor, s.button)}
    {...(target && { target })}
    onClick={() => editorClick()}
    href={template.editorUrl}
  >
    {t('template.viewer.info.edit.button')}
  </a>
);

interface DesktopOnlyNoticeProps extends WithTranslation {}

function DesktopOnlyNotice({ t }: DesktopOnlyNoticeProps) {
  return (
    <div className={s.desktopOnlyNotice} data-hook={infoPopUpDataHooks.desktopOnlyNotice}>
      {t('template.viewer.info.desktop.only.notice')}
    </div>
  );
}

export interface InfoPopUpProps extends WithTranslation {
  dataHook?: string;
  isMobile?: boolean;
}

const InfoPopUpCmp: FC<InfoPopUpProps> = ({ t, i18n, isMobile, dataHook }) => {
  const { isInfoShown, setIsInfoShown } = useUIContext();
  const { template } = useTemplateContext();

  const onEditorClick = () => {
    reportEditorClick({ origin: 'viewer_more' });
  };

  return (
    <div data-hook={dataHook} className={cs(s.popupWrapper, { [s.show]: isInfoShown })}>
      <div className={s.card}>
        {!isMobile && (
          <button
            data-hook={infoPopUpDataHooks.cardClose}
            className={s.closeButton}
            onClick={() => setIsInfoShown(!isInfoShown)}
          >
            <span className={s.hide}>{t('a11y.close.popup.button')}</span>
          </button>
        )}
        <div className={s.content}>
          <h1 data-hook={infoPopUpDataHooks.cardTitle} className={s.title}>
            {t('template.viewer.info.title', { title: template.title })}
          </h1>
          <div className={s.feature}>
            <h3 data-hook={infoPopUpDataHooks.cardGoodForTitle} className={s.subtitle}>
              {t('template.viewer.info.goodFor')}
            </h3>
            <p data-hook={infoPopUpDataHooks.cardGoodFor} className={s.runningText}>
              {template.goodFor}
            </p>
          </div>
          <div className={s.feature}>
            <h3 className={s.subtitle}>{t('template.viewer.info.description')}</h3>
            <p data-hook={infoPopUpDataHooks.cardDescription} className={s.runningText}>
              {template.description}
            </p>
          </div>
        </div>
        <div className={s.footer}>
          {isMobile ? (
            <DesktopOnlyNotice t={t} i18n={i18n} tReady />
          ) : (
            <EditButton i18n={i18n} tReady target="_blank" t={t} template={template} editorClick={onEditorClick} />
          )}
        </div>
      </div>
    </div>
  );
};

export const InfoPopUp = withTranslation()(InfoPopUpCmp);
