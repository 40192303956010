import React, { FC } from 'react';
import s from './mobileView.scss';
import { mobileViewDataHooks } from './MobileView.dataHooks';

interface Props {
  dataHook?: string;
  url: string;
  title: string;
}

export const MobileView: FC<Props> = ({ url, title, dataHook }) => {
  const previewUrl = `${url}?showMobileView=true`;

  return (
    <div data-hook={dataHook} className={s.display}>
      <div className={s.phone}>
        <div className={s.speaker} />
        <div className={`${s.display} ${s.device}`}>
          <iframe
            src={previewUrl}
            title={title}
            width="100%"
            height="100%"
            data-hook={mobileViewDataHooks.mobileIframe}
          />
        </div>
      </div>
    </div>
  );
};
