export const desktopAppDataHooks = {
  editorxToolbarWithSwitcher: 'editorx-toolbar-with-switcher',
  editorxTemplateDemo: 'editorx-template-demo',
  toolBar: 'tool-bar',
  templateDemo: 'template-demo',
  infoPopUp: 'info-pop-up',
  wixStudioToolbar: 'wix-studio-toolbar',
  wixStudioNewToolbar: 'wix-studio-new-toolbar',
  wixStudioTemplateDemo: 'wix-studio-template-demo',
};
