import React, { FC, PropsWithChildren } from 'react';
import { ExperimentContext, ExperimentContextShape } from './ExperimentContext';

interface ExperimentContextProviderProps {
  initialState: ExperimentContextShape;
}

export const ExperimentContextProvider: FC<PropsWithChildren<ExperimentContextProviderProps>> = ({
  initialState,
  children,
}) => {
  const contextValue: ExperimentContextShape = {
    activeExperiments: initialState.activeExperiments || [],
  };

  return <ExperimentContext.Provider value={contextValue}>{children}</ExperimentContext.Provider>;
};
