import React, { FC } from 'react';
import cs from 'classnames';
import s from './EditorXTemplateDemo.scss';
import { VIEW_MODE } from '../../utils/helpers';
import { StoreTemplate } from '../../context/TemplateContext/TemplateContext';

interface Props {
  dataHook?: string;
  template: StoreTemplate;
  viewMode: string;
}

export const EditorXTemplateDemo: FC<Props> = ({ template, viewMode, dataHook }) => {
  const isMobile = viewMode === VIEW_MODE.MOBILE;

  let previewUrl = template.url;
  if (isMobile) {
    previewUrl += '?showMobileView=true';
  }

  return (
    <div data-hook={dataHook} data-view-mode={viewMode} className={s.editorXTemplateDemo}>
      <iframe
        title={template.title}
        src={previewUrl}
        className={cs(s.iframe, {
          [s.tablet]: viewMode === VIEW_MODE.TABLET,
          [s.mobile]: viewMode === VIEW_MODE.MOBILE,
        })}
      />
    </div>
  );
};
