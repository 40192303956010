export function initWixRecorder(activeExperiments: string[]) {
  window.addEventListener('wixRecorderReady', async () => {
    const experiments = activeExperiments.reduce((acc: { [specName: string]: 'true' }, specName: string) => {
      acc[specName] = 'true';
      return acc;
    }, {});

    try {
      await window.wixRecorder!.addLabel('marketing-template-viewer');
      await window.wixRecorder!.withExperiments(experiments);
    } catch (error) {
      console.error(error);
    }
  });
}
