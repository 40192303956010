import React, { PropsWithChildren } from 'react';
import { UserData, UserDataContext } from './UserDataContext';

export type UserDataContextProviderProps = {
  initialState: { userData: UserData };
};

export const UserDataContextProvider: React.FC<PropsWithChildren<UserDataContextProviderProps>> = ({
  children,
  initialState,
}) => {
  return <UserDataContext.Provider value={initialState.userData}>{children}</UserDataContext.Provider>;
};
