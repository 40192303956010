import React, { FC, useEffect } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import cs from 'classnames';
import s from './mobileApp.scss';
import * as BIManager from '../../utils/bi';
import { MobileToolbar } from '../partials/MobileToolbar/MobileToolbar';
import { MobileEditorXToolbar } from '../partials/MobileEditorXToolbar/MobileEditorXToolbar';
import { fedopsLogger } from '../../utils/fedops-logger';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useUIContext } from '../../context/UIContext/UIContext';
import { InfoButton } from '../InfoButton/InfoButton';
import { InfoPopUp } from '../InfoPopUp/InfoPopUp';
import { mobileAppDataHooks } from './MobileApp.dataHooks';
import { MobileWixStudioToolbar } from '../partials/MobileWixStudioToolbar/MobileWixStudioToolbar';

const MobileAppCmp: FC<{ dataHook?: string } & WithTranslation> = ({ t, dataHook }) => {
  const { isEditButtonHidden, isInfoShown } = useUIContext();
  const { template } = useTemplateContext();
  const { url, isResponsive, title, isStudio } = template;
  const previewUrl = `${url}?showMobileView=true`;

  useEffect(() => {
    BIManager.reportLoadSuccess();
    if (template.isResponsive) {
      BIManager.reportResponsiveTemplateLoaded();
    }
    fedopsLogger.appLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toolbar: React.ReactNode;

  if (isStudio) {
    toolbar = <MobileWixStudioToolbar template={template} dataHook={mobileAppDataHooks.wixStudioToolbar} />;
  } else if (isResponsive) {
    toolbar = (
      <>
        <MobileEditorXToolbar
          template={template}
          isEditButtonHidden={isEditButtonHidden}
          dataHook={mobileAppDataHooks.newMobileEditorxToolbar}
        />
        ,
        <div className={s.goToBiggerScreen} data-hook={mobileAppDataHooks.biggerScreen}>
          {t('template.viewer.goToBiggerScreen')}
        </div>
        ,
      </>
    );
  } else {
    toolbar = (
      <MobileToolbar dataHook={mobileAppDataHooks.mobileToolBar}>
        <InfoButton dataHook={mobileAppDataHooks.infoView} />
      </MobileToolbar>
    );
  }

  return (
    <div data-hook={dataHook}>
      {toolbar}
      <iframe
        data-hook={mobileAppDataHooks.mobileIframe}
        title={title}
        src={previewUrl}
        width="100%"
        height="100%"
        className={cs(s.iframe, { [s.iframeHidden]: isInfoShown })}
      />
      {!isStudio && <InfoPopUp isMobile dataHook={mobileAppDataHooks.infoPopUp} />}
      {isResponsive && (
        <div className={s.seeAllExpressions} data-hook={mobileAppDataHooks.seeAllExpressions}>
          <a href="https://www.wix.com/editorx/templates" className={s.seeAllExpressionsLink}>
            <span className={s.seeAllExpressionsLinkText}>{t('template.viewer.seeAllExpressions')}</span>
            &nbsp;→
          </a>
        </div>
      )}
    </div>
  );
};

export const MobileApp = withTranslation()(MobileAppCmp);
