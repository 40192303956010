import React, { FC } from 'react';
import s from './Switcher.scss';
import { VIEW_MODE } from '../../../../utils/helpers';
import { SwitcherButton } from './SwitcherButton/SwitcherButton';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { switcherDataHooks } from './Switcher.dataHooks';

interface Props extends WithTranslation {
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
  dataHook?: string;
}

export const SwitcherCmp: FC<Props> = ({ viewMode, toggleViewMode, t, dataHook }) => (
  <div className={s.switcher} data-hook={dataHook}>
    <SwitcherButton
      viewMode={VIEW_MODE.DESKTOP}
      activeView={viewMode}
      tooltip={t('toolbar.tooltip.desktop')}
      toggleViewMode={toggleViewMode}
      dataHook={switcherDataHooks.desktopButton}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="23" viewBox="0 0 27 23">
        <path d="M23.7454 18.0715H3.25507C2.51427 18.0707 1.80404 17.8109 1.28021 17.3489C0.756387 16.8869 0.461746 16.2605 0.460938 15.6072V2.46429C0.461746 1.81094 0.756387 1.18455 1.28021 0.722561C1.80404 0.260572 2.51427 0.000713162 3.25507 0H23.7454C24.4862 0.000713162 25.1964 0.260572 25.7203 0.722561C26.2441 1.18455 26.5387 1.81094 26.5395 2.46429V15.6072C26.5387 16.2605 26.2441 16.8869 25.7203 17.3489C25.1964 17.8109 24.4862 18.0707 23.7454 18.0715ZM3.25507 1.64286C3.00815 1.64314 2.77144 1.72978 2.59684 1.88376C2.42224 2.03775 2.32401 2.24652 2.32369 2.46429V15.6072C2.32401 15.8249 2.42224 16.0337 2.59684 16.1877C2.77144 16.3417 3.00815 16.4283 3.25507 16.4286H23.7454C23.9923 16.4283 24.229 16.3417 24.4036 16.1877C24.5782 16.0337 24.6765 15.8249 24.6768 15.6072V2.46429C24.6765 2.24652 24.5782 2.03775 24.4036 1.88376C24.229 1.72978 23.9923 1.64314 23.7454 1.64286H3.25507Z" />
        <path d="M20.9509 21.3571H6.04883V23H20.9509V21.3571Z" />
      </svg>
    </SwitcherButton>
    <SwitcherButton
      viewMode={VIEW_MODE.TABLET}
      activeView={viewMode}
      tooltip={t('toolbar.tooltip.tablet')}
      toggleViewMode={toggleViewMode}
      dataHook={switcherDataHooks.tabletButton}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23">
        <path d="M16.15 23H2.85C2.09439 22.9993 1.36996 22.7394 0.835657 22.2774C0.301357 21.8155 0.000824786 21.1891 0 20.5357V2.46429C0.000824786 1.81094 0.301357 1.18455 0.835657 0.72256C1.36996 0.260572 2.09439 0.000713161 2.85 0H16.15C16.9056 0.000713161 17.63 0.260572 18.1643 0.72256C18.6986 1.18455 18.9992 1.81094 19 2.46429V20.5357C18.9992 21.1891 18.6986 21.8155 18.1643 22.2774C17.63 22.7394 16.9056 22.9993 16.15 23ZM2.85 1.64286C2.59814 1.64314 2.3567 1.72977 2.17861 1.88376C2.00052 2.03775 1.90033 2.24652 1.9 2.46429V20.5357C1.90033 20.7535 2.00052 20.9623 2.17861 21.1162C2.3567 21.2702 2.59814 21.3569 2.85 21.3571H16.15C16.4019 21.3569 16.6433 21.2702 16.8214 21.1162C16.9995 20.9623 17.0997 20.7535 17.1 20.5357V2.46429C17.0997 2.24652 16.9995 2.03775 16.8214 1.88376C16.6433 1.72977 16.4019 1.64314 16.15 1.64286H2.85Z" />
      </svg>
    </SwitcherButton>
    <SwitcherButton
      viewMode={VIEW_MODE.MOBILE}
      activeView={viewMode}
      tooltip={t('toolbar.tooltip.mobile')}
      toggleViewMode={toggleViewMode}
      dataHook={switcherDataHooks.mobileButton}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20">
        <path d="M2.432.027C1.289.162.35.928.07 1.952l-.057.211v15.675l.057.21c.124.455.352.826.718 1.172.435.41.982.664 1.624.753.268.038 9.908.038 10.176 0 .642-.089 1.189-.343 1.624-.753a2.44 2.44 0 0 0 .718-1.172l.058-.21V2.163l-.058-.211a2.435 2.435 0 0 0-.683-1.14c-.394-.376-.826-.604-1.409-.744l-.225-.054-5-.004C4.863.008 2.531.016 2.432.027m10.077 1.695a.913.913 0 0 1 .551.477l.053.114v15.375l-.071.142a.95.95 0 0 1-.442.417l-.137.066H2.538l-.138-.066a.95.95 0 0 1-.442-.417l-.07-.142V2.313l.052-.114a.928.928 0 0 1 .542-.476c.123-.042.395-.045 5.008-.046 4.697-.002 4.882 0 5.019.045" />
      </svg>
    </SwitcherButton>
  </div>
);

export const Switcher = withTranslation()(SwitcherCmp);
