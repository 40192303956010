import React, { FC } from 'react';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import * as BIManager from '../../../utils/bi';
import { WhiteToolBarButton } from '../WhiteToolBarButton/WhiteToolBarButton';
import s from './EditorXToolBar.scss';
import { Switcher } from './Switcher/Switcher';
import { EditorXLogo } from '../EditorXLogo/EditorXLogo';
import { VIEW_MODE } from '../../../utils/helpers';
import { StoreTemplate } from '../../../context/TemplateContext/TemplateContext';
import { editorXToolBarDataHooks } from './EditorXToolBar.dataHooks';

interface Props extends WithTranslation {
  dataHook?: string;
  template: StoreTemplate;
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
}

const EditorXToolBarCmp: FC<Props> = ({ t, template, viewMode, toggleViewMode, dataHook }) => (
  <div className={s.editorXToolBar} data-hook={dataHook}>
    <div className={s.logo}>
      <EditorXLogo isLarge dataHook={editorXToolBarDataHooks.logo} />
    </div>
    <div className={s.switcher}>
      <Switcher viewMode={viewMode} toggleViewMode={toggleViewMode} />
    </div>
    <div className={s.button}>
      <WhiteToolBarButton href={template.editorUrl} onClick={() => BIManager.reportTemplateEditButtonClick()}>
        {t('template.viewer.editTemplate')}
      </WhiteToolBarButton>
    </div>
  </div>
);

export const EditorXToolBar = withTranslation()(EditorXToolBarCmp);
