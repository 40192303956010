import React, { FC, useEffect } from 'react';

import s from './DesktopApp.scss';

import * as BIManager from '../../utils/bi';
import { fedopsLogger } from '../../utils/fedops-logger';
import { initDealer } from '../../utils/initDealer';

import { EditorXToolBar } from '../partials/EditorXToolBar/EditorXToolBar';
import { EditorXTemplateDemo } from '../EditorXTemplateDemo/EditorXTemplateDemo';
import { InfoPopUp } from '../InfoPopUp/InfoPopUp';
import { TemplateDemo } from '../TemplateDemo/TemplateDemo';
import { ToolBar } from '../ToolBar/ToolBar';
import { WixStudioToolBar as WixStudioNewToolBar } from '../partials/WixStudioNewToolBar/WixStudioToolBar';
import { WixStudioTemplateDemo } from '../WixStudioTemplateDemo/WixStudioTemplateDemo';

import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useUIContext } from '../../context/UIContext/UIContext';
import { useConfigContext } from '../../context/ConfigContext/ConfigContext';
import { desktopAppDataHooks } from './DesktopApp.dataHooks';

export const DesktopApp: FC<{ dataHook?: string }> = ({ dataHook }) => {
  const { viewMode, toggleViewMode, isInfoShown } = useUIContext();
  const { template } = useTemplateContext();
  const { lang, dealerCmsTranslationsUrl, dealerLightboxUrl } = useConfigContext();
  const { isResponsive, editorSessionId, siteId, metaSiteId, isStudio } = template;

  useEffect(() => {
    BIManager.reportLoadSuccess();
    if (isResponsive) {
      BIManager.reportResponsiveTemplateLoaded();
    }
    fedopsLogger.appLoaded();
    if (isStudio) {
      return;
    }
    initDealer({
      editorSessionId,
      metaSiteId,
      siteId,
      lang,
      dealerCmsTranslationsUrl,
      dealerLightboxUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.toggle('popup', isInfoShown);
  }, [isInfoShown]);

  return (
    <div data-hook={dataHook}>
      {isStudio ? (
        <div className={s.studioRoot}>
          <WixStudioNewToolBar
            viewMode={viewMode}
            toggleViewMode={toggleViewMode}
            dataHook={desktopAppDataHooks.wixStudioNewToolbar}
          />
          <WixStudioTemplateDemo viewMode={viewMode} dataHook={desktopAppDataHooks.wixStudioTemplateDemo} />
        </div>
      ) : template.isResponsive ? (
        <>
          <EditorXToolBar
            template={template}
            viewMode={viewMode}
            toggleViewMode={toggleViewMode}
            dataHook={desktopAppDataHooks.editorxToolbarWithSwitcher}
          />
          <EditorXTemplateDemo
            template={template}
            viewMode={viewMode}
            dataHook={desktopAppDataHooks.editorxTemplateDemo}
          />
          <InfoPopUp dataHook={desktopAppDataHooks.infoPopUp} />
        </>
      ) : (
        <>
          <ToolBar dataHook={desktopAppDataHooks.toolBar} />
          <TemplateDemo dataHook={desktopAppDataHooks.templateDemo} />
          <InfoPopUp dataHook={desktopAppDataHooks.infoPopUp} />
        </>
      )}
    </div>
  );
};
