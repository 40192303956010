import React from 'react';
import s from './SwitcherButton.scss';
import cs from 'classnames';
import { VIEW_MODE } from '../../../../../utils/helpers';

interface Props {
  viewMode: VIEW_MODE;
  activeView: string;
  tooltip: string;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
  children: React.ReactNode;
  dataHook?: string;
}

export const SwitcherButton = ({ viewMode, activeView, toggleViewMode, tooltip, children, dataHook }: Props) => (
  <button
    className={cs(s.switcherButton, { [s.active]: viewMode === activeView })}
    onClick={() => toggleViewMode(viewMode)}
    data-hook={dataHook}
  >
    {children}
    <span className={s.tooltip}>{tooltip}</span>
  </button>
);
