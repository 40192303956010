import React from 'react';

type LoggedInUserData = {
  isLoggedIn: true;
  profile: {
    id: string;
  };
};

type LoggedOutUserData = {
  isLoggedIn: false;
};

export type UserData = LoggedInUserData | LoggedOutUserData;

export const UserDataContext = React.createContext<UserData | undefined>(undefined);

export const useUserData = () => {
  const userData = React.useContext(UserDataContext);
  if (userData === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return userData;
};
